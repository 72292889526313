.side-background-ortobello {
    position: absolute;
    top: -400px;
    width: 500px;
    height: 800px;
    z-index: -9999999;
    background-size: cover;
    background-repeat: no-repeat;
    display: none;
}

.side-background-ortobello.left {
    left: -80px;
}

.side-background-ortobello.right {
    right: -40px;
    top: -100px;
    transform: rotateY(180deg);
}

.text-ortobello {
    font-size: 24px;
    color: #138773;
}

@media (max-width: 1024px) {
    .side-background-ortobello {
        display: none;
    }
}