.side-background-espositori {
    position: absolute;
    top: 350px;
    width: 500px;
    height: 800px;
    z-index: -9999999;
    background-size: cover;
    background-repeat: no-repeat;
}

.side-background-espositori.left {
    left: -250px;
}

.side-background-espositori.right {
    right: -250px;
    top: 400px;
    transform: rotateY(180deg);
}

.api-text {
    font-size: 15px;
}

.api-text h2 {
    font-size: 20px;
}

@media (max-width: 1024px) {
    .side-background-espositori {
        display: none;
    }
}