.side-background-tickets {
    position: absolute;
    top: -80px;
    bottom: 0;
    width: 500px;
    height: 800px;
    z-index: -9999999;
    background-size: cover;
    background-repeat: no-repeat;
}

.side-background-tickets.left {
    left: -80px;
}

.side-background-tickets.right {
    right: -40px;
    top: 100px;
    transform: rotateY(180deg);
}

.api-text {
    font-size: 15px;
}

.api-text h2 {
    font-size: 20px;
}

@media (max-width: 1024px) {
    .side-background-tickets {
        display: none;
    }
}