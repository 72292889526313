.navbar2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: white;
    padding: 10px 20px;
}

.navbar2 .logo {
    font-size: 1.5em;
}

.navbar2 .menu-icon {
    display: none;
    font-size: 1.5em;
    cursor: pointer;
}

.navbar2 .nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar2 .nav-links li {
    padding: 0 15px;
}

.navbar2 .nav-links a {
    color: white;
    text-decoration: none;
}

@media (max-width: 768px) {
    .navbar2 .menu-icon {
        display: block;
    }

    .navbar2 .nav-links {
        display: none;
        position: absolute;
        right: 0;
        top: 60px;
        flex-direction: column;
        width: 100%;
        background-color: #333;
    }

    .navbar2 .nav-links.open {
        display: flex;
    }

    .navbar2 .nav-links li {
        text-align: center;
        padding: 10px;
    }
}